import _ from "lodash";
import Vue from "vue";

import axios from "@/http/axios";
import productsModule from "@/store/modules/products";
import { getNested } from "@/store/utils";

// Need to to a deep clone because otherwise we will share the same state.
const clonedState = _.cloneDeep(productsModule.state);
const state = {
  ...clonedState,
  biggestConstituents: {},
};

const getters = {
  ...productsModule.getters,
  getBiggestConstituents:
    (state) =>
    ({ mode }) => {
      return getNested(state.biggestConstituents, mode);
    },
};

const mutations = {
  ...productsModule.mutations,
  FETCH_BIGGEST_CONSTITUENTS_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.biggestConstituents, mode, data);
  },
};

const actions = {
  ...productsModule.actions,
  async fetchBiggestConstituents({ commit }, { productId, mode }) {
    const params = {};
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/biggest-constituents`, {
        params: params,
      });
      commit("FETCH_BIGGEST_CONSTITUENTS_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_BIGGEST_CONSTITUENTS_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
