import _ from "lodash";
import Vue from "vue";

import axios from "@/http/axios";
import productsModule from "@/store/modules/products";
import { getNested } from "@/store/utils";

// Need to to a deep clone because otherwise we will share the same state.
const clonedState = _.cloneDeep(productsModule.state);
const state = {
  ...clonedState,
  weights: {},
};

const getters = {
  ...productsModule.getters,
  getWeights:
    (state) =>
    ({ mode }) => {
      return getNested(state.weights, mode);
    },
};

const mutations = {
  ...productsModule.mutations,
  FETCH_WEIGHTS_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.weights, mode, data);
  },
};

const actions = {
  ...productsModule.actions,
  async fetchWeights({ commit }, { productId, mode, period }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/weights`, {
        params: params,
      });
      commit("FETCH_WEIGHTS_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_WEIGHTS_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
